import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import './App.css';

function App() {
  const [query, setQuery] = useState('');
  const [result, setResult] = useState('');
  const [pptxUrl, setPptxUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [originalMaterials, setOriginalMaterials] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const handleCredentialResponse = (response) => {
      const userObject = jwt_decode(response.credential);
      setUser(userObject);
    };
    window.handleCredentialResponse = handleCredentialResponse;

    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/generate_rex', { query });
      setResult(response.data.rex_slide);
      setOriginalMaterials(response.data.original_materials);
      setPptxUrl(response.data.pptx_url);
    } catch (error) {
      console.error('Error fetching the REX slide:', error);
    }
    setLoading(false);
  };

  const handleRetry = async () => {
    setLoading(true);
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/generate_rex', { query });
      setResult(response.data.rex_slide);
      setOriginalMaterials(response.data.original_materials);
      setPptxUrl(response.data.pptx_url);
    } catch (error) {
      console.error('Error fetching the REX slide:', error);
    }
    setLoading(false);
  };

  const renderOriginalMaterials = () => {
    return originalMaterials.map((material, index) => (
      <div key={index}>
        <h4>Source: {material[0]}/{material[1]} (Page {material[2]})</h4>
        <p>{material[3]}</p>
      </div>
    ));
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>REX Slide Generator</h1>
        {user ? (
          <div>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Enter your query"
                required
              />
              <button type="submit" disabled={loading}>{loading ? 'Generating...' : 'Generate'}</button>
            </form>
            {originalMaterials.length > 0 && (
              <div>
                <h2>Original Materials</h2>
                {renderOriginalMaterials()}
              </div>
            )}
            {pptxUrl && (
              <div>
                <h2>Download PowerPoint</h2>
                <a href={pptxUrl} download="Output-REX.pptx">Download PowerPoint</a>
              </div>
            )}
          </div>
        ) : (
          <div>
            <div id="g_id_onload"
              data-client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              data-callback="handleCredentialResponse"
              data-auto_prompt="false">
            </div>
            <div className="g_id_signin"
              data-type="standard"
              data-size="large"
              data-theme="outline"
              data-text="sign_in_with"
              data-shape="rectangular"
              data-logo_alignment="left">
            </div>
          </div>
        )}
      </header>
    </div>
  );
}

export default App;
